<template>
 <el-dialog
  :title="null"
  :show-close="showclose"
  :visible.sync="Visible"
  :width="width"
  :before-close="handleClose"
  append-to-body
  :close-on-click-modal="clickmodal"
  style="height: 100vh;"
  center
>
  <div slot="title" class="dialog-title">
       <!-- <el-button type="primary" :icon="icon" circle size="mini"></el-button> -->
      <!-- <span>{{ title }}</span> -->
    </div>
  <div class="zdy">
    <slot />
    <div class="kuai"></div>
    <span slot="footer" class="dialog-footer" v-if="AcquiesceBtn">
    <el-button @click="handleClose" class="button-new">取 消</el-button>
    <el-button v-show="!viewType" type="primary" @click="Submit" :loading="SubmitLoading" class="button-new">确 定</el-button>
  </span>
  </div>
  
</el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props:{
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    icon: {
      type: String,
      default: 'el-icon-user'
    },
    SubmitLoading: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: String,
      default: ''
    },
    AcquiesceBtn: {
      type: Boolean,
      default: true
    },
    clickmodal: {
      type: Boolean,
      default: false
    },
    showclose: {
      type: Boolean,
      default: false
    },
  },
  created() {
  },
  data() {
    return {
      Visible: true,
    }
  },
  methods: {
    Submit() {
      this.$emit('Submit')
    },
    handleClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-title {
  span {
    margin-left: 5px;
  }
}
::v-deep .el-dialog {
  height: 100vh;
  transform: translate(-50%,-50%);
  left: 50%;
  top:50%;
  margin: 0 !important;
  // border-radius: 5px;
}
::v-deep .el-dialog__header {
  // border-bottom: 1px solid #dbe5ee;
  padding: 5px 10px;
   box-sizing: border-box;
}
::v-deep .el-dialog__body {
  padding: 0;
  height: calc(100vh - 10px);
  // height: 100vh;
}
.zdy {
  width: 100%;
  padding: 0 10px 10px;
  height: calc(100vh - 10px);
  // height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
}
::v-deep .el-dialog__footer {
  padding: 10px;
  // box-sizing: border-box;
  border-top: 1px solid #dbe5ee;
  border-bottom: 1px solid #dbe5ee;
  // background-color: aquamarine;
}

.button-new{
  width: 20vh;
}
.kuai{
  display: flex;
  margin-bottom: 50px;
}
.dialog-footer{
  display: flex;
  justify-content: center;
  // padding-bottom: 50px !important;
  // padding-top: 50px !important;
  // padding: 50px !important;
  // margin-top: 50px !important;
}
</style>