import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'reset-css';
import '@/assets/index.less';
import element from '@/ElementUi/index';
import 'element-ui/lib/theme-chalk/index.css';
import components from '@/components/index';

// 引入Viewer插件
import VueViewer, { directive as viewerDirective } from 'v-viewer';
// 引入Viewer插件的图片预览器的样式
import 'viewerjs/dist/viewer.css'; 
// 使用Viewer图片预览器
Vue.use(VueViewer)
// 用于图片预览的指令方式调用 在元素上加上会处理元素下所有的图片,为图片添加点击事件,点击即可预览
Vue.directive('viewer', viewerDirective({
  debug: true
}));

Vue.use(element);
Vue.use(components);
Vue.config.productionTip = false
window.eventBus = new Vue();

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
