import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mapindex',
    component: () => import(/* webpackChunkName: "login" */ '../views/map'),
    meta: {
      title: '登入'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
