import Vue from 'vue'

// import Button from './Button'
// import Table from './Table'
// import Pagination from './Pagination'
import Dialog from './Dialog'
// import ListBtn from './ListBtn'

const components = {
  install: function() {
    // Vue.component('Button', Button);
    // Vue.component('Table', Table);
    // Vue.component('Pagination', Pagination);
    Vue.component('Dialog', Dialog);
    // Vue.component('ListBtn', ListBtn);
  }
}

export default components